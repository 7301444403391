<template>
  <v-container>
    <div>
      <h1 class="mb-3"> Create {{ locationType.text }} Location</h1>
      <LocationForm ref="locationForm" @save="save" @cancel="cancel"/>
    </div>
  </v-container>
</template>
<script>
import event, { Events } from '../../../event'
import LocationForm from '@/components/Locations/LocationForm'
import { LocationTypeOptions } from '@/js/LocationTypes'

export default {
  name: 'CreateLocationView',
  components: { LocationForm },
  data: () => ({
    LocationTypeOptions,
    currentLocation: {},
    locationType: {}
  }),
  mounted () {
    this.getLocationType()
  },
  methods: {
    async save (newLocation) {
      this.currentLocation = newLocation
      this.currentLocation.location_type = this.locationType.value

      const url = this.$store.getters['Organization/apiUrl'] + '/locations'
      await window.axios.post(url, this.currentLocation)

      this.$store.commit('Organization/' + this.locationType.api, [])
      await this.$router.push({ name: this.locationType.index })
      event.emit(Events.SUCCESS, this.locationType.text + ' Location was successfully created')
    },
    cancel () {
      this.$router.push({ name: this.locationType.index })
    },
    getLocationType () {
      this.locationType = LocationTypeOptions.find(x => x.value === this.$route.params.locationType)
    }
  }
}
</script>
